import React from "react"
import Navbar from '../components/nav-bar'
import Footer from '../components/footer'

export default function Plans() {
    return(
      <>
        <header className="bg-custom3 pb-10">
          <Navbar />
          <h1 className="text-6xl sm:text-7xl lg:text-8xl font-inter font-extrabold mb-6 text-center text-white select-none">Plans</h1>
          <h2 className="text-xl sm:text-2xl lg:text-3xl font-inter font-light mb-20 px-4 text-center text-white select-none">We offer a free plan of our product which has many capabilities. <br />Our paid plans adds additional capabilities to enhance your users experiences.</h2>
        </header>
        <main className="bg-custom5">
            <div className="w-6/12 mx-auto grid grid-cols-1 font-inter lg:grid-cols-2 pt-24">
              <div className="mb-6 lg:mb-10 w-10/12 mx-auto p-6 border-4 border-custom4 rounded-3xl text-center">
                <h3 className="text-3xl md:text-4xl font-bold mb-4 text-custom1">Free</h3>
                <p className="text-xl md:text-2xl font-semibold">The free plan includes:</p>
                <ul className="text-lg font-light mt-6">
                  <li className="mb-4">Lyrics</li>
                  <li className="mb-4">Google Slides</li>
                  <li className="mb-4">Images</li>
                  <li className="mb-4">PowerPoint</li>
                  <li className="mb-4">Max 5 clients</li>
                </ul>
              </div>
              <div className="mb-6 lg:mb-10 w-10/12 mx-auto p-6 border-4 border-custom2 rounded-3xl text-center">
                <h3 className="text-3xl md:text-4xl font-bold mb-4 text-custom1">£5/month</h3>
                <p className="text-xl md:text-2xl font-semibold">The paid plan includes:</p>
                <ul className="text-lg font-light mt-6">
                  <li className="mb-4">Free Plan capabilities and...</li>
                  <li className="mb-4">Countdown Timers</li>
                  <li className="mb-4">Audio</li>
                  <li className="mb-4">Max 40 clients</li>
                </ul>
              </div>
              <div className="mb-6 lg:mb-10 w-10/12 mx-auto p-6 border-4 border-custom2 rounded-3xl text-center">
                <h3 className="text-3xl md:text-4xl font-bold mb-4 text-custom1">£10/month</h3>
                <p className="text-xl md:text-2xl font-semibold">The paid plan includes:</p>
                <ul className="text-lg font-light mt-6">
                  <li className="mb-4">£5 Plan capabilities and...</li>
                  <li className="mb-4">Stage View</li>
                  <li className="mb-4">Max 80 clients</li>
                </ul>
              </div>
              <div className="mb-6 lg:mb-10 w-10/12 mx-auto p-6 border-4 border-custom4 rounded-3xl text-center">
                <h3 className="text-3xl md:text-4xl font-bold mb-4 text-custom1">Extras</h3>
                <p className="text-xl md:text-2xl font-semibold">Our extras include:</p>
                <ul className="text-lg font-light mt-6">
                  <li className="mb-4">More clients: 5 extra clients per £1</li>
                  <li className="mb-4">Video: £1 per 200 minutes +  £1 per 1000 client minutes</li>
                  <li className="mb-4">For example: 10 clients x 10 minutes = 100 client minutes</li>
                </ul>
              </div>
            </div>
            <p className="font-light text-lg text-black text-center pb-8 px-28">Clients are prepaid, clients who attempt to join a full room will not be able to enter.<br />
            Video charges are post paid.</p>
        </main>
        <Footer />
      </>
    )
}

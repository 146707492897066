import React, {useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";


export function ModifySongWindow({ songsBank, setSongsBank, songsList, setSongsList }) {
    const [showModal, setShowModal] = useState(false)
    const [showInput, setShowInput] = useState(false)
    const [selectedSong, setSelectedSong] = useState('')
    const [title, setTitle] = useState('')
    const [lyrics, setLyrics] = useState('')
    const { user, getAccessTokenSilently } = useAuth0();
    async function RequestSend({setShowModal}) {
        const accessToken = await getAccessTokenSilently({
            audience: `https://presentdeck.eu.auth0.com/api/v2/`,
            scope: "read:current_user",
          });

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
            body: JSON.stringify({'songID': selectedSong.id, 'title': title, 'lyrics': lyrics})
        };
        fetch('https://present-deck.com/modifysong/editsong', requestOptions)
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            setShowModal(false)
            setShowInput(false)
            let newSongsBank = []
            for (let song of songsBank) {
                if (song.id === selectedSong.id) {
                    newSongsBank.push({'title': title, 'lyrics': lyrics, 'id': selectedSong.id})
                } else {
                    newSongsBank.push(song)
                }
            }
            setSongsBank(newSongsBank);
            setTitle('')
            setLyrics('')
            
            let newSongsList = songsList.map((el) => {
                return (newSongsBank.find( ({id}) => id === el.id))
            })
            setSongsList(newSongsList)
            /*console.log('songsBank', songsBank)
            console.log('songsList', songsList)
            console.log('newSongsList', newSongsList)*/

        }).catch (error => {
            console.log(error);
        }) 
    }
    return (
        <>
        <button
            className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}>Edit Song</button>
        {showModal ? (
            <>
            <div
                className="justify-center items-top flex overflow-x-hidden overflow-y-none fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-1/2 h-2/3 my-8 mx-auto max-w-3xl">
                {/*content*/}
                <div className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold">Edit Song</h3>
                    </div>
                    <ul className="grid my-6">
                        {songsBank.map((el) => (
                        <li key={el.id} onClick={() => {setShowInput(true)
                        setSelectedSong(el)
                        setTitle(el.title)
                        setLyrics(el.lyrics)}}
                        className="w-1/2 mt place-self-center cursor-pointer text-md bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                        {el.title}</li>
                        ))}
                    </ul>
                    {/*body*/}
                    {showInput ? (
                    <form className="m-2">
                        <div className="mb-4">
                        <input onInput={(e) => setTitle(e.target.value)} onChange={(e) => {setTitle(e.target.value)}} value={title} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="songInput" type="text" placeholder="Title..." />
                        </div>
                        <div className="mb-2">
                        <textarea onInput={(e) => setLyrics(e.target.value)} onChange={(e) => {setLyrics(e.target.value)}} value={lyrics} rows="10" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="lyricsInput" type="text" placeholder="Lyrics..." />
                        </div>
                    </form>
                    ) : null}
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                        className="h-12 w-32 text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}>Close</button>
                    <button
                        className="transition duration-200 ease-in-out bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => RequestSend({setShowModal})}>Edit Song</button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        </>
    );
}


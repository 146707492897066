import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/home';
import Client from './pages/client';
import Present from './pages/present';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Plans from './pages/plans'
import { useLocation } from "react-router-dom";

ReactDOM.render(
  <Auth0Provider
  domain="presentdeck.eu.auth0.com"
  clientId="u1jPngmIHF9qe4yu3ovAvFPqxw3j6mFS"
  redirectUri={window.location.origin}
  audience="https://presentdeck.eu.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
  >
    <React.StrictMode>
      <Router>
        <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/client">
              <Client />
            </Route>
            <Route path="/present">
              <Present />
            </Route>
            <Route path="/plans">
              <Plans />
            </Route>
          </Switch>
      </Router>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { AddSong } from "../components/presentFunctions/addSong";
import { DelSongWindow } from "../components/presentFunctions/delSong";
import { GetSongsRequest, SongsInList, PreviewLyricsMap, LiveLyricsMap, GoLive } from '../components/presentFunctions/generalFunctions'
import { ModifySongWindow } from "../components/presentFunctions/modifySong";
import { useAuth0 } from '@auth0/auth0-react'

export default function Main() {
    const [songsBank, setSongsBank] = useState([]);
    const [songsList, setSongsList] = useState([]);
    const [previewLyrics, setPreviewLyrics] = useState([]);
    const [liveLyrics, setLiveLyrics] = useState([]);
    const [room, setRoom] = useState(null)
    const [key, setKey] = useState(null)
    const ws = useRef(null);
    useEffect(() => {
        if (room !== null) {
            ws.current = new WebSocket(`wss://websockets.present-deck.workers.dev/api/room/${room}/websocket`)
            ws.current.addEventListener("message", event => {
            })
            ws.current.onopen = function () {
                ws.current.send(JSON.stringify({"name": "presenter"}))
            };
        }
    }, [room])

    return (
    <>
    <div className="block md:hidden grid">
        <h1 className="mt-32 text-3xl text-center">You cannot use this feature on this screen.</h1>
        <h2 className="text-2xl text-center mt-8">Please use a bigger screen or return back.</h2>
        <Link to="/" className="place-self-center mt-12"><button className="mt-4 transition duration-200 ease-in-out bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">Back</button></Link>
    </div>
    <div className="hidden md:block">
        <div className="grid grid-cols-11">
            <div id="previewTab" className="p-3 h-80 bg-yellow-100 col-span-5 overflow-y-auto">
                <h1 className="text-black font-bold text-4xl">Preview</h1>
                <PreviewLyricsMap previewLyrics={previewLyrics} />
            </div>
            <div id="liveTab" className="p-3 h-80 bg-red-200 col-span-6 overflow-y-auto">
                <h1 className="text-black font-bold text-4xl">Live</h1>
                <div id = "vertical-live" className="vertical-menu">
                    <LiveLyricsMap liveLyrics={liveLyrics} ws={ws} key={key} />
                </div>
            </div>
            <div id="functions" className="p-3 h-80 bg-yellow-500 col-span-5 overflow-y-auto">
                <h1 className="text-black font-bold text-4xl">Functions</h1>
                <AddSong songsBank={songsBank} setSongsBank={setSongsBank} />
                <DelSongWindow songsBank={songsBank} setSongsBank={setSongsBank} songsList={songsList} setSongsList={setSongsList} />
                <ModifySongWindow songsBank={songsBank} setSongsBank={setSongsBank} songsList={songsList} setSongsList={setSongsList} />
                <GoLive liveLyrics={liveLyrics} setLiveLyrics={setLiveLyrics} previewLyrics={previewLyrics} setPreviewLyrics={setPreviewLyrics} />
                <button
                    className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setSongsList([])}>Clear List</button>
                <RoomStart setRoom={setRoom} setKey={setKey} room={room} ws={ws} />

            </div>
            <div id="userContent" className="p-3 h-80 bg-blue-300 col-span-3 overflow-y-auto">
                <h1 className="text-black font-bold text-4xl mb-2">Songs</h1>
                <div id = "vertical-content">
                    <GetSongsRequest songsList={songsList} setSongsList={setSongsList} songsBank={songsBank} setSongsBank={setSongsBank} />
                </div>
            </div>
            <div id="liveList" className="p-3 h-80 bg-purple-200 col-span-3 overflow-y-auto">
                <h1 className="text-black font-bold text-4xl mb-2">List</h1>
                <div id = "vertical-list">
                    <SongsInList songsList={songsList} previewLyrics={previewLyrics} setPreviewLyrics={setPreviewLyrics} />
                </div>
            </div>
        </div>
        <Link to='/'><button className="mt-4 transition duration-200 ease-in-out bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">Back</button></Link>
    </div>
    </>
    )
}




export function RoomStart({setRoom, setKey, room, ws}) {
    const [showModal, setShowModal] = useState(false)
    const {isAuthenticated} = useAuth0();

    function close() {
        ws.current.send(JSON.stringify({'method': 'close'}))
        setRoom(null)

    }
        if (room === null) {
            return (
                <>
                <button
                    className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                            if (room === null && isAuthenticated) {
                                fetch('https://websockets.present-deck.workers.dev/api/room', {method: 'POST'})
                                .then(response => {
                                    return response.json();
                                }).then(jsonResponse => {
                                    if (jsonResponse.id) {
                                        setRoom(jsonResponse.id)
                                        setKey(jsonResponse.key)
                                        setShowModal(true)
                                    }
                                }).catch (error => {
                                    console.log(error);
                                }) 
                            } else if (room === null) {
                                alert('You must be signed in to start presenting!')
                            } else if (isAuthenticated) {
                                alert('A room is already open!')
                            }
                        }}>Start Room</button>
                </>
            );
        } else {
            return (
                <>
                <button
                    className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={close}>Stop Room</button>
                    {showModal ? (
                        <>
                        <div
                            className="justify-center items-top flex overflow-x-hidden overflow-y-none fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-1/2 h-2/3 my-8 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="items-start justify-between py-8 px-6 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-center text-4xl font-semibold">Room Created</h3>
                                </div>
                                {/*body*/}
                                <div>
                                    <h2 className="font-bold text-3xl text-center mt-8"> How to join:</h2>
                                    <p className="font-light text-md text-center px-10 mt-4 mb-10">You can join the room through the custom url, or you can go to https://present-deck.com/client and enter the code below:</p>
                                    <h3 className="font-bold text-xl text-center mt-4">Room URL:</h3>
                                    <p className="font-light text-md text-center px-10 mt-4 mb-10">https://present-deck.com/client/<br />{room.substring(0, 32)}<br/>{room.substring(32)}</p>
                                    <h3 className="font-bold text-xl text-center mt-4">Code:</h3>
                                    <p className="font-light text-md text-center px-10 mt-4 mb-10">{room.substring(0, 32)}<br/>{room.substring(32)}</p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="h-12 w-32 text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowModal(false)}>Close</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                    </>
            );
        }
}
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom';
import { Link, Redirect } from "react-router-dom"

export default function Client() {
    const [showModal, setShowModal] = useState(false)
    const [message, setMessage] = useState('')
    const ws = useRef(null)
    const path = useLocation().pathname.split('/')[2]
        useEffect( async () => {
            if (path) {
                ws.current = await new WebSocket(`wss://websockets.present-deck.workers.dev/api/room/${path}/websocket`)
                ws.current.addEventListener("message", event => {
                    let data = JSON.parse(event.data)
                    if (data.message) {
                      setMessage(JSON.parse(event.data).message)
                    }
                })
                ws.current.onopen = function () {
                  ws.current.send(JSON.stringify({"name": "client"}))
                  console.log('a')
                }
            } else {
                setShowModal(true)
            }
        }, [showModal])


  return (
    <>
        <div className="min-w-screen min-h-screen bg-black">
            <h1 className="text-5xl text-white font-bold ml-8 pt-8 px-8 h-full">{message}</h1>
            { showModal ? <SongInput setShowModal={setShowModal} /> : null}
        </div>
    </>
  )
}


export function SongInput({setShowModal}) {
    const [room, setRoom] = useState('')

    return (
        <>
            <div className="justify-items-center grid">
                <h2 className="font-bold text-4xl text-white text-center mt-20 mb-8">Room Code:</h2>
                <input type="text" value={room} onChange={e => setRoom(e.target.value)} className="border-2 border-black text-md w-6/12 mb-8 text-center" />
                <Link to={'/client/' + room} onClick={() => setShowModal(false)}><button
                    className="bg-transparent text-white hover:bg-green-500 border-4 border-green-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">Add Song
                </button></Link>
            </div>
        </>
    )
}
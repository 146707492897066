import React, {useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function DelSongWindow({songsBank, setSongsBank, songsList, setSongsList}) {
    const [showModal, setShowModal] = useState(false)
    const { user, getAccessTokenSilently } = useAuth0();

    async function RequestSend({songID}) {
        const accessToken = await getAccessTokenSilently({
            audience: `https://presentdeck.eu.auth0.com/api/v2/`,
            scope: "read:current_user",
        });
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`},
            body: JSON.stringify({"songID": songID})
        };
        fetch('https://present-deck.com/modifysong/delsong', requestOptions)
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse)
        }).catch (error => {
            console.log(error);
        })
        setSongsBank(songsBank.filter(song => song.id !== songID));
        setSongsList(songsList.filter(song => song.id !== songID));

        setShowModal(false)
        
    }

    return (
        <>
        <button
            className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}>Delete Song</button>
        {showModal ? (
            <>
            <div
                className="justify-center items-top flex overflow-x-hidden overflow-y-none fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-1/2 h-2/3 my-8 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold">Add Song</h3>
                    </div>
                    {/*body*/}
                    <ul>
                        {songsBank.map((el) => (
                        <li key={el.id} onClick={() => RequestSend({songID: el.id})}
                        className="cursor-pointer text-md bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                        {el.title}</li>
                        ))}
                    </ul>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                        className="h-12 w-32 text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}>Close</button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        </>
    );
}
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function GetSongsRequest({songsList, setSongsList, songsBank, setSongsBank}) {
    const { user, getAccessTokenSilently } = useAuth0();
    useEffect( async () => {
        if (user) {
            const accessToken = await getAccessTokenSilently({
                audience: `https://presentdeck.eu.auth0.com/api/v2/`,
                scope: "read:current_user",
              });
            const requestOptions = {
                method: 'GET',
                headers: {'Authorization': `Bearer ${accessToken}`}
            };
            fetch(`https://present-deck.com/modifysong/getsongs`, requestOptions)
            .then(response => {
                return response.json();
            }).then(jsonResponse => {
                setSongsBank(jsonResponse)
            }).catch(error => {
                console.log(error);
            }) 
        }
    }, [user])
    return (
        <ul>
            {songsBank.map((el) => (
            <li key={el.id}
            onClick = {() =>  {
            if (songsList.find( ({ id }) => id === el.id) === undefined) {
                setSongsList(songsList.concat(el))}
            }}
            className="cursor-pointer text-md bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            {el.title}</li>
            ))}
        </ul>
    )
}


export function PreviewLyricsMap({previewLyrics}) {
    return (
        <ul>
            {previewLyrics.map((el) => (
            <li key={el}
            className="cursor-pointer text-md bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            {el}</li>
            ))}
        </ul>
    )
}


export function GoLive({liveLyrics, setLiveLyrics, previewLyrics, setPreviewLyrics}) {
    function TriggerGoLive() {
        setLiveLyrics(previewLyrics);
        setPreviewLyrics(liveLyrics);
    }
    return (
        <button
            className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => TriggerGoLive()}>Go Live</button>
    )
}


export function LiveLyricsMap({liveLyrics, ws, key}) {
    return (
        <ul>
            {liveLyrics.map((el) => (
            <li key={el}
            className="cursor-pointer text-md bg-pink-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            onClick={() => ws.current.send(JSON.stringify({"message": el, "key": key}))}>
            {el}</li>
            ))}
        </ul>
    )
}


export function SongsInList({songsList, previewLyrics, setPreviewLyrics}) {
    return (
            <>
            <ul>
                {songsList.map((el) => (
                <li key={el.id}
                onClick = {() => 
                    setPreviewLyrics(el.lyrics.split('\n\n'))
                }
                className="cursor-pointer text-md bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                {el.title}</li>
                ))}
            </ul>
            </>
    )
}

import React from "react";
import { Link } from "react-router-dom";
import Logo from '../images/logo.png';
import LoginButton from '../components/loginButton'
import LogoutButton from '../components/logoutButton'
import { useAuth0 } from "@auth0/auth0-react";

function AccountButtons() {
    const {isAuthenticated} = useAuth0()
    if (isAuthenticated) {
        return <LogoutButton />;
    }
    return <LoginButton />;
}



export default function Main() {
    return (
        <nav className="mb-12 grid justify-items-center grid-cols-1 lg:grid-cols-2 lg:justify-items-start">
                <div className="lg:ml-36 xl:ml-48 2xl:ml-80">
                    <img src={Logo} alt="logo" className="h-24 inline-block" />
                </div>
                <ul className="hidden lg:block grid-cols-4 font-inter ml-8 mt-8 xl:ml-16 2xl:ml-32">
                    <Link to='/'><li className="mr-10 inline-block font-bold text-white text-xl">Home</li></Link>
                    <Link to='/client'><li className="mr-10 inline-block font-bold text-white text-xl">Client</li></Link>
                    <Link to='/present'><li className="mr-10 inline-block font-bold text-white text-xl">Presenting</li></Link>
                    <AccountButtons />
                </ul>
        </nav>
    )
}
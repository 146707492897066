import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <li onClick={() => logout({ returnTo: window.location.origin })} className="cursor-pointer inline-block font-bold text-white text-xl">
      Log out
    </li>
  );
};

export default LogoutButton;
import React from "react"
import { Link } from "react-router-dom"
import Navbar from '../components/nav-bar'
import Footer from '../components/footer'

export default function Home() {
    return(
      <>
        <header className="bg-custom3 pb-10">
          <Navbar />
          <h1 className="text-7xl sm:text-8xl lg:text-9xl font-inter font-extrabold mb-6 text-center text-white select-none">PRESENT<br />DECK</h1>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-inter font-light mb-12 px-4 text-center text-white select-none">An innovative solution to presenting</h2>
          <div className="grid justify-items-center mb-10 sm:mb-20">
            <Link to="/client"><button className="font-inter text-xl text-white transition duration-500 ease-in-out mb-6 inline-block w-48 h-16 md:w-60 md:h-20 bg-custom2 p-4 text-center rounded-md hover:shadow-2xl hover:bg-custom1 border-solid border-4 border-custom2 transform hover:scale-105 hover:-translate-y-2 font-bold align-middle">View Mode</button></Link>
            <Link to="/present"><button className="hidden md:block font-inter text-xl text-white transition duration-500 ease-in-out inline-block w-48 h-16 md:w-60 md:h-20 bg-custom4 p-4 text-center rounded-md hover:shadow-2xl hover:bg-custom1 border-solid border-4 border-custom4 transform hover:scale-105 hover:-translate-y-2 font-bold align-middle">Present Mode</button></Link>
          </div>
          <span className="inline-block animate-bounce flex justify-center">
            <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
            </svg>
          </span>
        </header>
        <main className="bg-">
            <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-10">
              <div className="mb-6 lg:mb-10 p-6">
                <h3 className="text-3xl md:text-4xl font-inter font-bold mb-4 text-custom1">Easy-to-use</h3>
                <p className="text-xl md:text-2xl font-inter font-light">Present Deck is an easy-to-use solution to presenting, whether it is simple song lyrics, images, Google Slides, or other technologies, Present Deck is the simple solution.</p>
              </div>
              <div className="mb-6 lg:mb-10 p-6">
                <h3 className="text-3xl md:text-4xl font-inter font-bold mb-4 text-custom1">Reliable</h3>
                <p className="text-xl md:text-2xl font-inter font-light">Ever been in a panicked state? Your outdated AV solution has broken again! Sign up today and you'll be setup in a matter of minutes. Present Deck is intuitive to use, and requires little to no training.</p>
              </div>
              <div className="mb-6 lg:mb-10 p-6">
                <h3 className="text-3xl md:text-4xl font-inter font-bold mb-4 text-custom1">Modern</h3>
                <p className="text-xl md:text-2xl font-inter font-light">We have taken inspiration from many existing solutions, and added our modern twist! So try out Present Deck now!</p>
              </div>
              <div className="mb-6 lg:mb-10 p-6">
                <h3 className="text-3xl md:text-4xl font-inter font-bold mb-4 text-custom1">Free</h3>
                <p className="text-xl md:text-2xl font-inter font-light mb-2">Although we have a paid plan, most of the capabilities are provided for free!</p>
                <Link to="/plans"><p className="font-inter text-xl md:text-2xl font-medium text-lg align-middle text-custom3">Plans ></p></Link>
              </div>
            </div>
        </main>
        <Footer />
      </>
    )

}

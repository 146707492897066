import React from "react"
import {Link} from 'react-router-dom'
import Logo from '../images/logo.png';

const Footer = () => {
  return(
    <div className="bg-custom3 text-custom5">
        <div className="pt-8 p-2 grid grid-cols-2 w-5/6 mx-auto gap-4 font-inter mb-4 font-medium text-lg md:text-xl">
            <ul className="mx-auto text-center">
                <Link to="/"><li className="mb-2">Home</li></Link>
                <Link to="/present" className="mb-2 hidden md:block"><li>Present</li></Link>
                <Link to="/client"><li className="mb-2">View</li></Link>
                <Link to="/plans"><li className="mb-2">Plans</li></Link>
            </ul>
            <ul className="mx-auto text-center">
                <Link to="/contact"><li className="mb-2">Contact us</li></Link>
                <Link to="/terms"><li className="mb-2">Terms & Conditions</li></Link>
                <Link to="/privacy"><li className="mb-2">Privacy Policy</li></Link>
            </ul>
        </div>
        <img src={Logo} alt="logo" className="h-40 flex mx-auto pb-4" />
    </div>
  )
}

export default Footer